<template>
  <div class="page">
    <div class="auth-form__wrapper">
      <div class="auth-form__logo-wrapper">
        <a :href="getLandingUrl" class="auth-form__logo">
          <img src="/img/logo.svg" alt="Logo">
        </a>
      </div>
      <form class="auth-form" id="login-form">
        <h2 class="form-title">Reset Your password</h2>
        <div class="details">
          <div class="input-group password">
            <input class="form-control" :type="showPassword ? 'text' : 'password'" v-model="password" placeholder="Password">
            <img class="vuesax-linear-eye" src="/img/vuesax-linear-eye-slash-1.svg" @click.prevent="showPassword = !showPassword" />
            <span class="invalid-feedback" v-if="passwordError.length > 0">{{ passwordError }}</span>
          </div>
          <div class="input-group password">
            <input class="form-control" :type="showPassword ? 'text' : 'password'" v-model="confirmPassword" placeholder="New password">
            <img class="vuesax-linear-eye" src="/img/vuesax-linear-eye-slash-1.svg" @click.prevent="showPassword = !showPassword" />
            <span class="invalid-feedback" v-if="confirmPasswordError.length > 0">{{ confirmPasswordError }}</span>
          </div>
          <div class="auth-form__btn">
            <button v-if="!load" class="btn wide submit" @click.prevent="resetPassword">Reset password</button>
            <div v-else class="load"><CircleLoad /></div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import CircleLoad from '@/components/img/CircleLoad'

export default {
	name: "RestorePassword",
	  components: {
	    CircleLoad
	 },
	data() {
	    return {
	      load: false,
        password: '',
	      confirmPassword: '',
        passwordError: '',
	      confirmPasswordError: '',
        showPassword: false,
	    }
	  },
	  computed: {
	    getLandingUrl () {
	      return process.env.VUE_APP_LANDING_URL
	    }
	  },
	  methods: {
		  async resetPassword() {
		      this.load = true
          this.passwordError = ''
          this.confirmPasswordError = ''
		      const that = this
		      await axios.post(process.env.VUE_APP_BACKEND_API_URL + 'auth/new-password', {
              password: this.password,
              confirm_password: this.confirmPassword,
		          token: this.$route.params.token,
		        })
		        .then(function (response) {
		          if (response.data.error) {
		            for (let key in response.data.errors) {
		              if (key === 'password' && response.data.errors[key][0]) {
		                that.passwordError = response.data.errors[key][0]  
		              } else if (key === 'confirm_password' && response.data.errors[key][0]) {
                    that.confirmPasswordError = response.data.errors[key][0]  
                  }
		            }
		            return
		          }
              const clientPassword = that.password
              that.password = ''
              that.confirmPassword = ''
              that.passwordError = ''
              that.confirmPasswordError = ''
		          that.$noty.success("You have successfully changed your password")
              that.login(response.data.email, clientPassword)
		        })
		        .catch(function (error) {
		          console.log(error);
		        })
		      this.load = false
		    },
        async login(email, password) {
          this.load = true
          const that = this
          await axios.post(process.env.VUE_APP_BACKEND_API_URL + 'auth/login', {
              email: email,
              password: password,
            })
            .then(function (response) {
              const { token, expiresIn } = response.data
              that.$store.dispatch('setToken', { token, expiresIn })
              that.$store.dispatch('setUser')
              that.$store.dispatch('setContent')
              that.get2faSettings() 
            })
            .catch(function (error) {
              console.log(error);
            })
          this.load = false
        },
        async get2faSettings () {
          const that = this
          await axios.get(process.env.VUE_APP_BACKEND_API_URL + 'settings/customer-two-factor-on-login')
            .then(function ({ data }) {
              that.twoFactorOnLogin = (+data.customer_two_factor_on_login) ? true : false;
              const twoFactorOnLogin = that.twoFactorOnLogin
              that.$store.dispatch('setTwoFactorOnLogin', { twoFactorOnLogin });
              if (twoFactorOnLogin && data.code_is_not_empty) {
                that.$noty.success('Please, check your email - we send you two factor code.')
                that.$router.replace({ name: "LoginCode" })
              } else {
                that.$store.dispatch('removeTwoFactorOnLogin')
                that.$router.replace({ name: "Personal" })
              } 
            })
            .catch(function (error) {
              console.log(error);
            })
        },
	  },
}
</script>


<style lang="scss" scoped>
.page {
  position: relative;
  z-index: 1;
  &:before{
    content: "";
    z-index:-1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: url('/img/nero.svg');
    background-repeat: no-repeat;
  }
  .auth-form__logo{
    max-width: 120px;
    &-wrapper{
      display: flex;
      width: 100%;
      justify-content: center;
    }
  }
  .auth-form__wrapper{
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    gap: 32px;
    justify-content: center;
    align-items: center;
  }
  .auth-form, .details{
    width: 480px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px
  }
  .details{
    gap: 24px;
  }
  .form-title {
    font-family: var(--title-bold-1-font-family);
    font-weight: var(--title-bold-1-font-weight);
    color: var(--on-surface);
    font-size: var(--title-bold-1-font-size);
    letter-spacing: var(--title-bold-1-letter-spacing);
    line-height: var(--title-bold-1-line-height);
    white-space: nowrap;
    font-style: var(--title-bold-1-font-style);
  }
  .login-with__social{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    width: 100%;
    &-btns{
      width: 100%;
    }
    &-text{
      font-family: var(--placeholder-field-font-family);
      font-weight: var(--placeholder-field-font-weight);
      color: var(--on-surface);
      font-size: var(--placeholder-field-font-size);
      letter-spacing: var(--placeholder-field-letter-spacing);
      line-height: var(--placeholder-field-line-height);
      white-space: nowrap;
      font-style: var(--placeholder-field-font-style);
    }
  }
  .input-group{
    width: 100%;
    input{
      display: flex;
      align-items: center;
      gap: 4px;
      padding: 16px 24px !important;
      position: relative;
      align-self: stretch;
      width: 100%;
      flex: 0 0 auto;
      border-radius: 5px;
      border: 1px solid;
      border-color: #ababb2;
      margin-bottom: 0 !important;
    }
    &.password{
      position: relative;
      img{
        position: absolute;
        right: 25px;
        top: 17px;
      }
    }
  }
  .btn{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    padding: 16px 24px;
    position: relative;
    align-self: stretch;
    flex: 0 0 auto;
    border-radius: 5px;
    border: 1px solid;
    border-color: #09090b;
    color: #09090b;
    font-family: var(--for-button-font-family);
    font-weight: var(--for-button-font-weight);
    font-size: var(--for-button-font-size);
    letter-spacing: var(--for-button-letter-spacing);
    line-height: var(--for-button-line-height);
    white-space: nowrap;
    font-style: var(--for-button-font-style);
    &.wide{
      width: 100%;
    }
    &.submit{
      background-color: #1763fb;
      border-color: #1763fb;
      color: #fff
    }
  }
  .auth-form__forgot{
    display: flex;
    justify-content: end;
    width: 100%;
  }
  .auth-form__btn{
    display: flex;
    width: 100%;
  }
  .forgot-password{
    margin-left: auto;
    position: relative;
    width: fit-content;
    font-family: var(--placeholder-field-font-family);
    font-weight: var(--placeholder-field-font-weight);
    color: #3177ff;
    font-size: var(--placeholder-field-font-size);
    letter-spacing: var(--placeholder-field-letter-spacing);
    line-height: var(--placeholder-field-line-height);
    white-space: nowrap;
    font-style: var(--placeholder-field-font-style);
  }
  .invalid-feedback{
    color: red;
  }
}
</style>